//Modules
import React from "react";
//CSS
import styles from "./Date.module.scss";

export default function Date({ day }) {
  return (
    <div>
      <h2>{day}</h2>
    </div>
  );
}
